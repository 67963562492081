// Vendor files
var $ = window.jQuery = window.$ = require('./vendor/jquery-1.11.1.min');

var $$_ = window.$$_ = require('./shared/core'); 

require('./vendor/slick'); 

// include your scripts here

// require('./shared/textarea-autosize'); 
// require('./shared/img'); 
// require('./shared/map'); 
// require('./shared/parallax'); 
// require('./shared/select'); 
// require('./shared/search'); 

// expose your functions to the global scope for testing
var mxm = {};

// init some things


// template for new JS files, a la browserify
$(function($){

	var $window = $(window),
	$body = $('body'),
	$main = $('.main'),
	$gallery = $('.gallery_slider');




	//                                                         88
	//                                       ,d                88
	//                                       88                88
	// 8b       d8  ,adPPYba,  88       88 MM88MMM 88       88 88,dPPYba,   ,adPPYba,
	// `8b     d8' a8"     "8a 88       88   88    88       88 88P'    "8a a8P_____88
	//  `8b   d8'  8b       d8 88       88   88    88       88 88       d8 8PP"""""""
	//   `8b,d8'   "8a,   ,a8" "8a,   ,a88   88,   "8a,   ,a88 88b,   ,a8" "8b,   ,aa
	//     Y88'     `"YbbdP"'   `"YbbdP'Y8   "Y888  `"YbbdP'Y8 8Y"Ybbd8"'   `"Ybbd8"'
	//     d8'
	//    d8'

	// var tag = document.createElement('script');

	// tag.src = "https://www.youtube.com/iframe_api";
	// var firstScriptTag = document.getElementsByTagName('script')[0];
	// firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

	// var player;

	// function onPlayerReady(event) {
 //    event.target.playVideo();
 //    console.log('READY')
 //  }

 //  // 5. The API calls this function when the player's state changes.
 //  //    The function indicates that when playing a video (state=1),
 //  //    the player should play for six seconds and then stop.
 //  var done = false;
 //  function onPlayerStateChange(event) {
 //    if (event.data == YT.PlayerState.PLAYING && !done) {
 //      setTimeout(stopVideo, 6000);
 //      done = true;
 //    }
 //  }
 //  function stopVideo() {
 //    player.stopVideo();
 //  }

	// function onYouTubeIframeAPIReady() {
	//   player = new YT.Player('player_01', {
	//     // height: '390',
	//     // width: '640',
	//     // videoId: 'M7lc1UVf-VE',
	//     events: {
	//       'onReady': onPlayerReady,
	//       'onStateChange': onPlayerStateChange
	//     }
	//   });

	//   console.log('////////PLAYER', player)


	// }

	// console.log(YT)

	//           88 88            88
	//           88 ""            88
	//           88               88
	// ,adPPYba, 88 88  ,adPPYba, 88   ,d8
	// I8[    "" 88 88 a8"     "" 88 ,a8"
	//  `"Y8ba,  88 88 8b         8888[
	// aa    ]8I 88 88 "8a,   ,aa 88`"Yba,
	// `"YbbdP"' 88 88  `"Ybbd8"' 88   `Y8a

		$gallery.slick({
			prevArrow: '<div class="slick-prev"></div>',
			nextArrow: '<div class="slick-next"></div>',
			dots: false,
			initialSlide: 0,
			// appendDots: '.gallery_slide'
			// speed: 1500,
			// appendDots: $('.'),
			// autoplay: true,
			// autoplaySpeed: 5000,
		});

		$gallery.on('beforeChange', function(event, slick, currentSlide, nextSlide){

			console.log(event, slick, currentSlide, nextSlide)

		  $main.attr('data-slide', nextSlide);
		}); 

		$gallery.on('afterChange', function(slick, currentSlide){
		  console.log(
		  	// slick, 
		  	// currentSlide, 
		  	currentSlide.currentSlide
	  	);

	  	// var $next = $main.find('.slick-active'),
	   //  	_nextIsShowing = $next.hasClass('showing');


	    // _nextIsShowing

	    if (currentSlide.currentSlide === 0){
		  	// player.play().then(function() {
		  	// 	console.log('PLAYING')
		  	// })
	    }else{
	    	// console.log(player)
		  	// player.pause().then(function() {
		  	// 	console.log('PAUSED')
		  	// })    	
	    }

	    console.log(
	    	$gallery.find('.slick-active.showing'),
	    	$gallery.find('.slick-active.showing').attr('data-slug')
	    	// $(currentSlide)

	  	)

	  	// window.location.hash = $main.find('.slick-active').attr('data-slug');
		});


		
		
		
		// ,adPPYYba, 8b,dPPYba, 8b,dPPYba,  ,adPPYba,  8b      db      d8
		// ""     `Y8 88P'   "Y8 88P'   "Y8 a8"     "8a `8b    d88b    d8'
		// ,adPPPPP88 88         88         8b       d8  `8b  d8'`8b  d8'
		// 88,    ,88 88         88         "8a,   ,a8"   `8bd8'  `8bd8'
		// `"8bbdP"Y8 88         88          `"YbbdP"'      YP      YP
		
		var $arrow = $('.arrow');

		$arrow.on('click', function(e) {
			var $that = $(this),
				theWindowHeight = $window.height();

			$('html,body').animate({
				scrollTop: theWindowHeight * $that.attr('data-point')
			}, 500);
		});


		var $getitonsteam = $('.getitonsteam');

		$getitonsteam.on('click', function() {
			$body.toggleClass('waiting');
		});





});
